/* eslint-disable no-console */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../security";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiJobsSlice = createApi({
  reducerPath: "apiJobs",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl,
    prepareHeaders: async (headers) => {
      const token = await sec.getAccessTokenSilently()();

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getJobsData: builder.query({
      query: ({ filters, page, pageSize }) =>
        `/api/v1/jobs?page=${page}&per=${pageSize}&${filters}`
    }),
    getJobsSpecialtiesOptions: builder.query({
      query: () => `/api/v1/jobs/specialties_options`
    }),
    getJobsFilters: builder.query({
      query: () => `/api/v1/jobs/filters`,
      transformResponse: (response: any) => {
        return response.reduce((acc: any, filter: any) => {
          /* eslint-disable no-param-reassign */
          acc[filter.label] = filter.options_for_select;
          return acc;
        }, {});
      }
    }),
    getJobsWithId: builder.query({
      query: ({ jobId }) => `/api/v1/targets/${jobId}/jobs`,
      transformResponse: (response: any) => {
        return response.jobs;
      }
    }),
    patchJobsWithId: builder.mutation({
      query: ({ jobId, jobsIds }) => ({
        url: `/api/v1/targets/${jobId}/jobs/status`,
        method: "PATCH",
        body: { job_ids: jobsIds }
      })
    }),
    getJobFolders: builder.query({
      query: () => `/api/v1/job_folders/list`
    }),
    postJobFolder: builder.mutation({
      query: ({ name }) => ({
        url: `/api/v1/job_folders`,
        method: "POST",
        body: {
          job_folder: {
            name
          }
        }
      })
    }),
    patchJobFolder: builder.mutation({
      query: ({ id, name }) => ({
        url: `/api/v1/job_folders/${id}`,
        method: "PATCH",
        body: {
          job_folder: {
            name
          }
        }
      }),
      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiJobsSlice.util.updateQueryData(
            "getJobsData",
            { filters: "", page: 1, pageSize: 1000 },
            (draft) => {
              const updateFolder = draft?.job_folders.find(
                (item: { id: any }) => item.id === id
              );

              if (updateFolder) {
                updateFolder.name = name;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    deleteJobFolder: builder.mutation({
      query: ({ folderValue }) => ({
        url: `/api/v1/job_folders/${folderValue}`,
        method: "DELETE"
      }),
      async onQueryStarted({ folderValue }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          apiJobsSlice.util.updateQueryData(
            "getJobsData",
            { filters: "", page: 1, pageSize: 1000 },
            (draft) => {
              const deleteFolder = draft?.job_folders.find(
                (item: { id: any }) => item.id === folderValue
              );

              const jobsInFolder = draft?.jobs.filter(
                (job: { job_folder_id: any }) =>
                  job.job_folder_id === folderValue
              );

              if (jobsInFolder) {
                jobsInFolder.forEach((job: { id: any }) => {
                  const updateJob = draft?.jobs.find(
                    (item: { id: any }) => item.id === job.id
                  );

                  if (updateJob) {
                    updateJob.job_folder_id = null;
                  }
                });
              }

              if (deleteFolder) {
                draft.job_folders = draft.job_folders.filter(
                  (item: { id: any }) => item.id !== folderValue
                );
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      }
    }),
    moveJobToFolder: builder.mutation({
      query: ({ jobId, folderValue }) => ({
        url: `/api/v1/jobs/${jobId}/move`,
        method: "PUT",
        body: { job_folder_id: folderValue }
      }),
      async onQueryStarted(
        { jobId, folderValue },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiJobsSlice.util.updateQueryData(
            "getJobsData",
            { filters: "", page: 1, pageSize: 1000 },
            (draft) => {
              const updateJob = draft?.jobs.find(
                (item: { id: any }) => item.id === jobId
              );
              const addIdToFolder = draft?.job_folders.find(
                (item: { id: any }) => item.id === folderValue
              );
              const removeIdFromFolder = draft?.job_folders.find(
                (item: { id: any }) => item.id === updateJob.job_folder_id
              );

              if (updateJob) {
                updateJob.job_folder_id = folderValue;
              }
              if (addIdToFolder) {
                addIdToFolder.job_ids.push(jobId);
              }
              if (removeIdFromFolder) {
                removeIdFromFolder.job_ids = removeIdFromFolder.job_ids.filter(
                  (id: any) => id !== jobId
                );
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    pinJob: builder.mutation({
      query: ({ jobId }) => ({
        url: `/api/v1/jobs/${jobId}/pin`,
        method: "PATCH"
      }),
      async onQueryStarted({ jobId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiJobsSlice.util.updateQueryData(
            "getJobsData",
            { filters: "", page: 1, pageSize: 1000 },
            (draft) => {
              const updateJob = draft?.jobs.find(
                (item: { id: any }) => item.id === jobId
              );

              if (updateJob) {
                updateJob.pinned = true;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    unpinJob: builder.mutation({
      query: ({ jobId }) => ({
        url: `/api/v1/jobs/${jobId}/unpin`,
        method: "PATCH"
      }),
      async onQueryStarted({ jobId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiJobsSlice.util.updateQueryData(
            "getJobsData",
            { filters: "", page: 1, pageSize: 1000 },
            (draft) => {
              const updateJob = draft?.jobs.find(
                (item: { id: any }) => item.id === jobId
              );

              if (updateJob) {
                updateJob.pinned = false;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    })
  }),
  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const {
  useGetJobsDataQuery,
  useGetJobsSpecialtiesOptionsQuery,
  useGetJobsFiltersQuery,
  useGetJobsWithIdQuery,
  usePatchJobsWithIdMutation,
  useGetJobFoldersQuery,
  usePostJobFolderMutation,
  usePatchJobFolderMutation,
  useDeleteJobFolderMutation,
  useMoveJobToFolderMutation,
  usePinJobMutation,
  useUnpinJobMutation
} = apiJobsSlice;
