import { createSlice } from "@reduxjs/toolkit";
import { ActiveJob, AddNewJobInitialValues } from "./models/job.interface";

interface JobState {
  activeJob: ActiveJob | null;
  addNewJobInitialValues: AddNewJobInitialValues;
}

const initialState: JobState = {
  activeJob: {
    id: null,
    title: null,
    min_year: null,
    max_year: null,
    job_title_id: null,
    job_title: {
      id: null,
      title: null,
      category: null
    },
    job_posting_data_attributes: null,
    locations: [],
    manually_created: false,
    displayed_description: null,
    contact: null,
    email: null,
    phone: null,
    created_job_skills: []
  },
  addNewJobInitialValues: {
    job_title_id: null,
    title: null,
    min_year: null,
    max_year: null,
    practice_type: [],
    locations: [],
    displayed_description: null,
    skills: [],
    specialties: []
  }
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setActiveJob: (state, action) => {
      state.activeJob = action.payload;
    },
    setAddNewJobValues: (state, action) => {
      state.addNewJobInitialValues = action.payload;
    },
    resetAddNewJobValues: (state) => {
      state.addNewJobInitialValues = initialState.addNewJobInitialValues;
    },
    resetActiveJob: (state) => {
      state.activeJob = initialState.activeJob;
    }
  }
});

export const {
  setActiveJob,
  resetActiveJob,
  setAddNewJobValues,
  resetAddNewJobValues
} = jobSlice.actions;
export default jobSlice.reducer;
