import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiServerUrl = process.env.REACT_APP_HAISTACK_API_URL;

export const apiPublicSlice = createApi({
  reducerPath: "apiPublic",
  baseQuery: fetchBaseQuery({
    baseUrl: apiServerUrl
  }),

  tagTypes: [],

  endpoints: (builder) => ({
    getPublicInfo: builder.query({
      query: ({ id }) => `/api/v1/shared_target_lists/${id}`
    }),
    getMarketMapData: builder.query({
      query: ({ jobId, token }) =>
        `/jobs/${jobId}/market_intel?shared_target_list_id=${token}`
    }),
    getSharedConnections: builder.query({
      query: ({ accessToken, attorneyId }) =>
        `/api/v1/attorneys/${attorneyId}/shared_connections?shared_target_list_id=${accessToken}`
    }),
    postPartnerResponse: builder.mutation({
      query: ({ jobId, attorneyId, status }) => ({
        url: `/api/v1/shared_target_lists/${jobId}/partner_responses`,
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          partner_responses: [
            {
              attorney_id: attorneyId,
              status
            }
          ]
        })
      })
    }),
    postSubmitSharedTargetList: builder.mutation({
      query: ({ token }) => ({
        url: `/api/v1/shared_target_lists/${token}/complete_review`,
        method: "POST"
      })
    })
  }),
  refetchOnMountOrArgChange: true
});

// Export hooks for usage in functional components
export const {
  useGetPublicInfoQuery,
  useGetMarketMapDataQuery,
  useGetSharedConnectionsQuery,
  usePostPartnerResponseMutation,
  usePostSubmitSharedTargetListMutation
} = apiPublicSlice;
