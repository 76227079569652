import { Box, Button as MuiIconButton } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

export interface Props {
  readonly id: string;
  readonly icon: ReactNode;
  readonly variant?: "primary" | "secondary" | "ghost" | "circle";
  readonly size?: "sm" | "md" | "lg";
  readonly disabled?: boolean;
  readonly onClick?: (event: any) => void;
  readonly sx?: object;
}

interface IconButtonClass {
  name: string;
  style: string;
  boxStyle: string;
}

const iconButtonProps: Array<IconButtonClass> = [
  {
    name: "primary",
    boxStyle: "w-fit leading-none rounded border border-gray-4",
    style:
      "min-w-fit bg-default-white shadow-none shadow-[0_1px_0] shadow-gray-shadow stroke-gray-7 " +
      "hover:shadow-[0_1px_0] hover:shadow-gray-shadow hover:bg-gray-1 " +
      "focus:bg-default-white focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow active:bg-primary-8 " +
      "active:shadow-none active:first:stroke-default-white " +
      "disabled:cursor-not-allowed disabled:shadow-[0_1px_0] disabled:shadow-gray-shadow disabled:bg-default-white disabled:opacity-50"
  },
  {
    name: "secondary",
    boxStyle: "w-fit leading-none rounded border border-gray-4",
    style:
      "min-w-fit bg-default-white shadow-none shadow-[0_1px_0] shadow-gray-shadow stroke-gray-7 " +
      "hover:shadow-[0_1px_0] hover:shadow-gray-shadow hover:bg-gray-1 " +
      "focus:bg-default-white focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow " +
      "active:bg-primary-8 active:shadow-none active:first:stroke-default-white " +
      "disabled:cursor-not-allowed disabled:shadow-[0_1px_0] disabled:shadow-gray-shadow disabled:bg-default-white disabled:opacity-50"
  },
  {
    name: "ghost",
    boxStyle: "w-fit rounded border border-default-transparent",
    style:
      "min-w-fit bg-default-white shadow-none stroke-gray-7 " +
      "hover:shadow-[0_1px_0] hover:shadow-gray-shadow hover:bg-gray-1 " +
      "focus:bg-default-white focus:shadow-[0_0_0_1px] focus:shadow-default-white focus:shadow-[0_0_0_4px] focus:shadow-primary-shadow " +
      "active:bg-primary-8 active:shadow-none active:first:stroke-default-white " +
      "disabled:cursor-not-allowed disabled:bg-default-white"
  },
  {
    name: "circle",
    boxStyle: "w-fit rounded-full border border-gray-4",
    style: "rounded-full hover:bg-gray-1"
  }
];

function IconButton(props: Props) {
  const { id, icon, variant, size, onClick, sx, disabled } = props;

  const [style, setStyle] = useState<string>("");
  const [boxStyle, setBoxStyle] = useState<string>("");

  const handleStyleChange = () => {
    let cssStyle = iconButtonProps.find((classes) => {
      return classes.name === variant;
    })?.style;
    const cssBoxStyle = iconButtonProps.find((classes) => {
      return classes.name === variant;
    })?.boxStyle;
    if (size === "sm") cssStyle = `${cssStyle} p-0.5`;
    if (size === "md") cssStyle = `${cssStyle} p-1.5`;
    if (size === "lg") cssStyle = `${cssStyle} p-2`;

    setStyle(cssStyle ?? "");
    setBoxStyle(cssBoxStyle ?? "");
  };

  useEffect(() => {
    handleStyleChange();
  }, []);

  useEffect(() => {
    handleStyleChange();
  }, [variant, size]);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  return (
    <Box className={boxStyle} onClick={onClick} sx={sx}>
      <MuiIconButton
        id={id}
        className={style}
        disabled={disabled}
        onClick={handleOnClick}
      >
        {icon}
      </MuiIconButton>
    </Box>
  );
}

IconButton.defaultProps = {
  variant: "primary",
  onClick: () => {
    /* parent should handle this */
  },
  size: "md",
  disabled: false,
  sx: {}
};

export default IconButton;
